<template>
  <v-main>
    <introNavigation />
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card class="elevation-4 mx-auto">
            <v-toolbar dark color="primary">
              <v-toolbar-title>{{
                $t("login.signInYourAccount")
              }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form ref="form" v-model="valid">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="email"
                        :rules="rules.emailRules"
                        :label="$t('common.email')"
                        @keypress.enter="onLogin"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="password"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required]"
                        :type="show1 ? 'text' : 'password'"
                        :label="$t('common.password')"
                        @keypress.enter="onLogin"
                        @click:append="show1 = !show1"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-center align-center">
              <v-btn
                align-center
                justify-center
                color="primary"
                @click="onLogin"
                >{{ $t("common.login") }}</v-btn
              >
            </v-card-actions>
            <!-- <v-card-actions class="justify-center align-center"> -->
            <div style="padding: 8px">
              <div class="justify-center align-center" style="flex-wrap: wrap; display: flex;">
                <!-- <div class="col-sm-12 col-md-6 col-lg-6 align-center" style="padding: 5px"> -->
                  <google-button-component
                    v-if="ENABLE_BINDING_GOOGLE"
                    :data-width="147"
                    style="margin: 0"
                    @onEmitData="googleBtnEmitData"
                  />
                <!-- </div> -->
                <!-- <div class="col-sm-12 col-md-6 col-lg-6" style="padding: 5px">
                  <a
                    v-if="ENABLE_BINDING_FACEBOOK"
                    class="btn btn-primary btn-block btn-social"
                    @click="onClickLoginFacebook">
                    <i class="fab fa-facebook"/> {{ $t('common.loginWithFacebook') }}
                  </a>
                </div> -->
              </div>
            <!-- </v-card-actions> -->
            </div>
            <p class="pt-2 pointer m-0" @click="onGoToForgotPassword">
              {{ $t("login.forgotPassword") }}
            </p>
            <p class="py-2 pointer" @click="onGoToRegister">
              {{ $t("common.createAccount") }}
            </p>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <confirmModal
      ref="confirmModal"
      :title="confirmModalTitle"
      @confirm="confirmCreateAccount"
      @cancel="cancelCreateAccount"
    />
  </v-main>
</template>

<script>
import IntroNavigation from "Components/IntroNavigation";
import functionUtils from "Utils/functionUtils";
import { mapActions } from "vuex";
import { isCookieEnabled, setCookie, removeCookie } from "tiny-cookie";
import SocialNetworkType from 'Enum/socialNetworkType'
import ConfirmModal from "Components/ConfirmModal";
import GoogleButtonComponent from 'Components/GoogleButtonComponent'
export default {
  components: {
    IntroNavigation,
    GoogleButtonComponent,
    ConfirmModal
  },
  data() {
    return {
      valid: false,
      show1: false,
      password: "",
      email: "",
      rules: {
        required: (value) => !!value || this.$t("login.thisFieldIsRequired"),
        emailRules: [
          (v) => !!v || this.$t("login.thisFieldIsRequired"),
          (v) => /.+@.+\..+/.test(v) || this.$t("login.emailMustBeValid"),
        ],
      },
      ENABLE_BINDING_GOOGLE: process.env.VUE_APP_ENABLE_BINDING_GOOGLE.trim() === 'true',
      ENABLE_BINDING_FACEBOOK: process.env.VUE_APP_ENABLE_BINDING_FACEBOOK.trim() === 'true',
      confirmModalTitle: ''
    };
  },
  methods: {
    onLogin: function() {
      if (this.$refs.form.validate()) {
        let filter = {
          email: this.email,
          password: this.password,
        };
        this.LOGIN(filter)
          .then(
            function(res) {
              let token = res.data.token;
              let userId = res.data.id;
              if (isCookieEnabled()) {
                setCookie("userId", userId);
                setCookie("token", token);
              }
              this.$toast.open({
                message: this.$t("common.success"),
                type: "success",
                ...this.$toastConfig,
              });
              this.$router.push({
                name: "ConfirmEmailDomain",
              });
            }.bind(this)
          )
          .catch(
            function(error) {
              if (isCookieEnabled()) {
                removeCookie("token");
                removeCookie("userId");
                removeCookie('isActiveLink')
              }
              if (!functionUtils.isNull(error.response.data.errorText)) {
                let errorText = error.response.data.errorText;
                this.$toast.open({
                  message: errorText,
                  type: "error",
                  ...this.$toastConfig,
                });
              } else {
                this.$toast.open({
                  message: this.$t("common.somethingWentWrong"),
                  type: "error",
                  ...this.$toastConfig,
                });
              }
            }.bind(this)
          );
      }
    },
    /**
     * Go to register
     */
    onGoToRegister: function() {
      this.$router.push({
        name: "Register",
      });
    },
    googleBtnEmitData: function (data) {
      if (data) {
        let id = data.id
        this.checkGoogleLogin(id, SocialNetworkType.GOOGLE, true, data)
      }
    },
    onClickLoginGoogle: function () {
      this.$gAuth
        .signIn()
        .then(GoogleUser => {
          // on success do something
          var profile = GoogleUser.getBasicProfile();
          var fullName = profile.getName()
          var avatarUrl = profile.getImageUrl()
          var email = profile.getEmail()
          if (Object.keys(profile).length > 0) {
            let id = profile.getId()
            let userProfile = {
              id: id,
              fullName: fullName,
              avatarUrl: avatarUrl,
              email: email
            }
            this.googleData = userProfile
            this.checkGoogleLogin(id, SocialNetworkType.GOOGLE, true, userProfile)
          }
        })
        // .catch(error => {
        //   console.log('error', error)
        // })
    },
    checkGoogleLogin: function (socialId, socialType, isCustomerPortal, extraInfo) {
      let data = {
        socialId: socialId,
        socialType: socialType,
        isConfirmCreateAccount: this.isConfirmCreateAccount
      }
      if (extraInfo) {
        data['fullName'] = extraInfo.fullName
        data['avatarUrl'] = extraInfo.avatarUrl
        data['email'] = extraInfo.email
      }
      if (isCustomerPortal) {
        data['isCustomerPortal'] = isCustomerPortal
      }
      this.isLoading = true
      this.LOGIN_WITH_GOOGLE(data).then(
        function (res) {
          let isShowModalConfirmCreateAccount = res.data.isShowModalConfirmCreateAccount !== undefined
            ? res.data.isShowModalConfirmCreateAccount : null
          if (isShowModalConfirmCreateAccount === null) {
            this.$toast.clear()
            this.$toast.open({
              message: this.$t("common.success"),
              type: "success",
              ...this.$toastConfig,
            });
            this.$router.push({
              name: "ConfirmEmailDomain",
            });
          } else {
            // 
            this.$refs.confirmModal.onShowModal();
            this.confirmModalTitle = this.$t('socialNetwork.notiConfirmCreateAccountWithGoogle')
          }
        }.bind(this)
      ).catch(
        function (error) {
          this.isLoading = false
          if (!functionUtils.isNull(error.response.data.errorText)) {
            let errorText = error.response.data.errorText
            this.$toast.clear()
            this.$toast.open({
              message: this.$t(errorText),
              type: "error",
              ...this.$toastConfig,
            });
          } else {
            this.$toast.clear()
            this.$toast.open({
              message: this.$t('login.somethingWentWrong'),
              type: "error",
              ...this.$toastConfig,
            });
          }
        }.bind(this)
      )
    },
    confirmCreateAccount: function () {
      this.isConfirmCreateAccount = true
      this.checkGoogleLogin(this.googleData.id, SocialNetworkType.GOOGLE, true, this.googleData)
    },
    cancelCreateAccount: function () {
      this.isConfirmCreateAccount = false
      this.onHideModal()
    },
    onGoToForgotPassword: function() {
      this.$router.push({
        name: "ForgotPassword",
      });
    },
    ...mapActions(["LOGIN", "LOGIN_WITH_GOOGLE"]),
  },
};
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
.btn-social {
  position: relative;
  padding-left: 44px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  height: 34px;
}

.btn-social>:first-child {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 32px;
  height: 100%;
  line-height: 34px;
  font-size: 1.6em;
  text-align: center;
  border-right: 1px solid rgba(0,0,0,0.2);
}

.btn-google {
  color: #fff;
  border-color: rgba(0,0,0,0.2);
  background: rgb(66, 133, 244);
}
</style>
